<template>
  <div class="component-main">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 text">
          <h1 class="font-nanum">
            <b>프론트리</b>
            <span>[frʌntri:]</span>
            <div>프론트엔드 개발자로 자라나기</div>
          </h1>
          <h2>
            <div>온라인으로 레슨 받고 성장하세요!</div>
          </h2>
          <div class="actions">
            <router-link to="/lessons" class="btn btn-point">라이브 레슨</router-link>
            <router-link to="/contact" class="btn btn-outline-point">문의하기</router-link>
          </div>
        </div>
        <div class="col-lg-6 image">
          <img src="/assets/img/img.main.svg" alt="Chracter for main page" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useStore } from "vuex";

export default {
  props: {
    visible: Boolean,
    callback: Function,
    load: Function,
  },
  setup() {
    const store = useStore();

    return {
      store,
    };
  },
};
</script>

<style lang="scss" scoped>
.component-main {
  position: relative;
  width: 100%;

  .row {
    padding: 170px 0;

    > div {
      &.image {
        position: relative;
      }
    }

    h1 {
      position: relative;
      font-weight: normal;
      margin-bottom: 25px;

      b {
        color: $colorPointHover;
        font-size: 32px;
        font-weight: 900;
      }

      span,
      div {
        font-size: 27px;
      }

      div {
        margin-top: 15px;
      }

      &.en {
        font-size: 25px;

        b {
          font-size: 35px;
        }
      }
    }

    h2 {
      font-size: 21px;
      position: relative;
      margin-bottom: 25px;
      line-height: 190%;
      color: #212121;
    }

    .actions {
      margin: 0 -10px;

      .btn {
        margin: 10px 5px;
        font-size: 15px;
        padding: 15px;
        width: 160px;
        box-shadow: none;
        outline: 0;
        font-weight: normal;
      }
    }

    &:after {
      clear: both;
      display: table;
      content: " ";
    }
  }

  @media (max-width: 991px) {
    .row {
      text-align: center;
      padding: 50px 15px;
    }
  }

  @media (max-width: 767px) {
    .row {
      h1 {
        margin-bottom: 17.5px;

        b {
          font-size: 25px;
        }

        span,
        div {
          font-size: 20px;
        }

        div {
          margin-top: 15px;
        }
      }

      h2 {
        font-size: 16px;
        margin-bottom: 15px;
      }

      > div {
        &:first-child {
          margin-bottom: 50px;
        }
      }

      .actions .btn {
        width: 129px;
      }
    }
  }
}
</style>
